/*
 * @Author: your name
 * @Date: 2020-06-15 09:01:56
 * @LastEditTime: 2020-06-22 14:20:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \src\service\other.js
 */ 
import { get } from "../common/query";
//
export const GET_DIAMON_PARAM = data => get("/diamondprice/getParameter", data);
export const GET_DIAMON_PRICE = data => get("/diamondprice/getPrice", data);
export const GET_STAR_DEGIGNER = data => get("/designer/list", data);
export const GET_NEWS = data => get("topics/getinfo", data);
export const GET_NEWSList = data => get("topics/getlist", data);
export const GET_PROLIST = data => get("/designer/prolist", data);