<template>
  <div class="news-list">
    <div class="title">最新资讯</div>
    <div class="label">{{banner.title}}</div>
    <div class="banner">
      <img :src="banner.firstpic.picUrl" alt @click="goNewsDetail(banner.id)"/>
    </div>
    <div class="container">
      <div class="title">{{banner.title}}</div>
      <div class="label" @click="goNewsDetail(banner.id)">点击阅读</div>
      <div class="list">
        <div class="ware-item" v-for="(item, index) in wareList" :key="index"  @click="goNewsDetail(item.id)">
          <div class="img">
            <img :src="item.firstpic.picUrl" alt />
          </div>
          <div class="info" @click="goNewsDetail(item.id)">
            <div class="ts">
              <div class="date">{{ item.ct | dateFormat }}</div>
              <div class="desc">{{ item.title }}</div>
            </div>
            <div class="btn" @click="goNewsDetail(item.id)">点击阅读</div>
          </div>
        </div>
      </div>
      <div class="more" v-show="total<=this.wareList.length+1">没有更多了....</div>
    </div>
  </div>
</template>

<script>
import { GET_NEWSList } from "../../service/other";

export default {
  name: "NewsList",
  components: {},
  data() {
    return {
      banner: {
        firstpic: {}
      },
      wareList: [],
      pagenation: {
        start: 0,
        size: 12
      },
      curpage: 0,
      total: 0
    };
  },
  computed: {},
  created() {
    this.bind();
    this.getNewsList();
  },
  beforeDestroy() {
    this.app.removeEventListener("scroll", this.scrollEvent);
  },
  methods: {
    bind() {
      var app = document.getElementById("sapp");
      app.addEventListener("scroll", this.scrollEvent);
    },
    scrollEvent(e) {
      let scrollHeight = e.target.scrollHeight;
      let height = e.target.getBoundingClientRect().height;
      let scrollTop = e.target.scrollTop;
      if (
        scrollHeight - scrollTop < height + 400 &&
        !this.loading &&
        this.total > this.wareList.length + 1
      ) {
        this.loading = true;
        this.curpage++;
        this.pagenation.start = this.pagenation.size * this.curpage;
        this.getNewsList();
      }
    },
    getNewsList() {
      GET_NEWSList({ ...this.pagenation }).then(res => {
        this.loading = false;
        if (res.status === 200) {
          this.wareList.push(...res.attachment.datas)
          this.banner = this.wareList[0]
          this.total = res.attachment.total;
        }
        console.log(res);
      });
    },
    goNewsDetail(id) {
      this.$router.push({ path: "/postdetails.html", query: { id } });
      // this.$router.push({path:'/createLogin.html'})
    }
  }
};
</script>
<style lang="less" scoped>
.ts{
  padding-left: 32px;
  .date{
    line-height: 50px;
  }
}
.news-list {
  .title {
    text-align: center;
    padding-top: 90px;
    font-size: 36px;
    line-height: 1;
    margin: 0 auto 10px;
  }
  .label {
    width: 608px;
    font-size: 16px;
    text-align: center;
    margin: 50px auto 76px;
    word-break: break-all;
  }
  .banner {
    width: 1274px;
     height: 390px;
    margin: 0 auto;
    overflow: hidden;
    &:hover{
      cursor: pointer;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .container {
    width: 1274px;
    margin: 20px auto 200px;
    .title {
      font-size: 18px;
      margin-bottom: 24px;
      width: 400px;
      line-height: 30px;
    }
    .label {
      width: 304px;
      line-height: 40px;
      border: 1px solid #666; /*no*/
      margin-bottom: 98px;
      &:hover{
        cursor: pointer;
      }
    }
    .list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 100px;
     .ware-item:hover {
       .info {
         right: 0;
       }
     }
      .ware-item {
        width: 627px;
        height: 312px;
        margin-bottom: 23px;
        position: relative;
        overflow: hidden;
        &:first-child{
          display: none;
        }
        .img {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .info {
          position: absolute;
          top: -40px;
          right: -313px;
          transition: 0.3s;
          width: 313px;
          height: 100%;
          border: 1px solid #666; /*no*/
          border-left: none;
          padding: 32px 16px 36px 16px;
          background: rgba(0, 0, 0, 0.5);
          color: #fff;
          .date {
            font-size: 12px;
            width: 260px;
          }
          .desc {
            font-size: 16px;
            line-height: 24px;
            width: 90%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          .btn {
            position: absolute;
            left: 32px;
            top: 230px;
            width: 120px;
            height: 40px;
            line-height: 36px;
            text-align: center;
            border-radius: 20px;
            border: 1px solid #fff; /*no*/
            color: #fff;
            cursor: pointer;
          }
        }
      }
    }
    .more {
      font-size: 22px;
      line-height: 1;
      color: #999;
      text-align: center;
    }
  }
}
</style>
